body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.error {
  color: red;
  font-size: 12px;
}
.alignCenter {
  text-align: center;
}
.l-size {
  font-size: 18px;
}
.m-size {
  font-size: 16px;
}
.s-size {
  font-size: 14px;
}
.email {
  font-size: 14px;
}
.text-bold {
  font-weight: bolder;
}
.p-left-1 {
  padding-left: 10px;
}
.m-left-1 {
  margin-left: 10px;
}
.m-b-1 {
  margin-left: 10px;
}
.form-label {
  width: 30%;
}
.color-b {
  color: #336699;
  border: 5px solid gray;
}
.selling-pin-box {
  margin-left: 50px;
  margin-right: 50px;
}
.ui.bulleted.list > .item {
  margin-left: 10px;
}
.ui.inline.loader {
  position: absolute;
  top: 10px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
@media screen and (max-width: 600px) {
  .m-size {
    font-size: 18px;
  }
  .error {
    font-size: 14px;
  }
  .s-size {
    font-size: 15px;
  }
  .l-size {
    font-size: 14px;
  }
  .email {
    font-size: 12px;
  }
  .selling-pin-box {
    margin-left: 20px;
    margin-right: 20px;
  }
  .ui.inline.loader {
    left: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ui.inline.loader {
    left: 20px;
  }
}
